import {
  spfOne,
  spfTwo,
  spfThree,
  spfFour,
  bestSellerOne,
  bestSellerTwo,
  bestSellerThree,
  bestSellerFour,
  newArrOne,
  newArrTwo,
  newArrThree,
  newArrFour,
} from "../assets/images/index";

// =================== NavBarList Start here ====================
export const navBarList = [
  {
    _id: 1001,
    title: "Home",
    link: "/",
  },
  {
    _id: 1002,
    title: "Shop",
    link: "/shop",
  },
  {
    _id: 1003,
    title: "About",
    link: "/about",
  },
  {
    _id: 1004,
    title: "Contact",
    link: "contact",
  },
  {
    _id: 1005,
    title: "Journal",
    link: "/journal",
  },
];
// =================== NavBarList End here ======================
// =================== Special Offer data Start here ============
export const SplOfferData = [
  {
    _id: "201",
    img: spfOne,
    productName: "Cap for Boys",
    price: "35.00",
    color: "Blank and White",
    badge: true,
    sold:  "791",
    des: "",
  },
  {
    _id: "202",
    img: newArrFour,
    productName: "Tea Table",
    price: "180.00",
    color: "Gray",
    badge: true,
    sold: "729",
    des: "",
  },
  {
    _id: "203",
    img: spfThree,
    productName: "Headphones",
    price: "25.00",
    color: "Mixed",
    badge: true,
    sold: "729",
    des: "",
  },
  {
    _id: "204",
    img: spfFour,
    productName: "Sun glasses",
    price: "220.00",
    color: "Black",
    badge: true,
    sold: "729",
    des: "",
  },
];
// =================== Special Offer data End here ==============

// =================== PaginationItems Start here ===============

export const paginationItems = [

  // =================== electronics ID:1000 ============
  {
    _id: 1000,
    img: "https://www.buysolar.pk/wp-content/uploads/2023/05/canadian-solar-555-575w.png",
    cat: "electronics",
    productName: "Canadian N Type 575w ",
    price: "45/P.W",
    color: "Black",
    badge: true,
    sold: "Sold: 835",
    des: "",
  },
  {
    _id: 1001,
    img: spfThree,
    cat: "electronics",
    productName: "Headphones",
    price: "1830.00",
    color: "Mixed",
    badge: true,
    sold: "Sold: 585",
    des: "",
  },
  
  {
    _id: 1002,
    img: newArrTwo,
    cat: "electronics",
    productName: "Smart Watch",
    price: "2250.00",
    color: "Black",
    badge: true,
    sold: "Sold: 879",
    des: "",
  },
{
    _id: 1003,
    img: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQGjnCqQHYP0roIdf4Mo6Fy7--OmDLlfVJKXX-xA6nc6SG_zwiNqzbdqVsA&s=10",
    cat: "electronics",
    productName: "Mini Wireless Camera",
    price: "2250.00",
    color: "Mixed",
    badge: true,
    sold: "Sold: 206",
    des: "",
  },
  {
    _id: 1002,
    img: "https://hhcdropshipping.com/Member/wp-content/uploads/2024/05/Untitled-design-1-1.png",
    cat: "electronics",
    productName: "(deal Pack Of 2) Crystal Lamp & M90 Air Buds",
    price: "3099.00",
    color: "Mix",
    badge: true,
    sold: "Sold: 360",
    des: "",
  },
  {
    _id: 1002,
    img: "https://hhcdropshipping.com/Member/wp-content/uploads/2024/02/portable-rechargeable-torch-led-flashlight-power-bank-hammer-and-strong-glass-and-seat-belt-1000x1000-3-900x900.webp",
    cat: "electronics",
    productName: "High-Power Led Flashlight",
    price: "1499.00",
    color: "Mix",
    badge: true,
    sold: "Sold: 292",
    des: "",
  },
  {
    _id: 1004,
    img: "https://static-01.daraz.pk/p/8073746d51b2d09f86aabdac5df37e1f.jpg",
    cat: "electronics",
    productName: "LCD Writing & Drawing Tab",
    price: "749.00",
    color: "Mixed",
    badge: true,
    sold: "Sold: 612",
    des: "",
  },

  {
    _id: 1005,
    img: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQrEqZZhSLQ1jvqbI8YdxS8CKoVT1CbRZA9Mw&usqp=CAU",
    cat: "electronics",
    productName: "Jinko 585 Bifacial N Type ",
    price: "48.00/watt",
    color: "Black",
    badge: true,
    sold: "Sold: 1042",
    des: "",
  },
  {
    _id: 1006,
    img: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTRvvedUzqJIpAYXICj8XXzkQdjPEOaQwJKmA&usqp=CAU",
    cat: "electronics",
    productName: "Juice Cup ",
    price: "1899",
    color: "mix",
    badge: true,
    sold: "Sold: 689",
    des: "",
  },
  {
    _id: 1007,
    img: "https://m.media-amazon.com/images/I/61WDt315gaL._AC_UF1000,1000_QL80_.jpg",
    cat: "electronics",
    productName: "portable juicer ",
    price: "1799",
    color: "mix",
    badge: true,
    sold: "Sold: 801",
    des: "",
  },
  {
    _id: 1008,
    img: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS0Du2dDUYM1vTy3ZIvVBKWMepbYguE1222Vw&usqp=CAU",
    cat: "electronics",
    productName: "M10 Bluetooth Wireless Headphones  ",
    price: "1599",
    color: "mix",
    badge: true,
    sold: "Sold: 791",
    des: "",
  },
  {
    _id: 1009,
    img: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQWBbSYs6KFX6oPoOUG10L3ywSAXvdizxC_Kw&usqp=CAU",
    cat: "electronics",
    productName: "Mobile Phone Holder ",
    price: "999",
    color: "mix",
    badge: true,
    sold: "Sold: 143",
    des: "",
  },
  {
    _id: 1010,
    img: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT9BW3CCb7te1uV0yUbOiSAKFjimf-4PcdPhw&usqp=CAU",
    cat: "electronics",
    productName: "Airpord Pro-2 ",
    price: "3389",
    color: "white",
    badge: true,
    sold: "Sold: 319",
    des: "",
  },


  // =================== kitchenware ID:2000   ============
    {
    _id: 2009,
    img: "https://hhcdropshipping.com/Member/wp-content/uploads/2024/06/71yyXhB40L._AC_SL1000_-768x768.jpg",
    cat: "kitchenware",
    productName: "Non Stick Grill BBQ 32cm",
    price: "2099",
    color: "Black",
    badge: true,
    sold: "Sold: 1457",
    des: "",
  },
  {
    _id: 2000,
    img: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTRvvedUzqJIpAYXICj8XXzkQdjPEOaQwJKmA&usqp=CAU",
    cat: "kitchenware",
    productName: "Juice Cup ",
    price: "1899",
    color: "mix",
    badge: true,
    sold: "Sold: 724",
    des: "",
  },
  {
    _id: 2001,
    img: "https://hhcdropshipping.com/Member/wp-content/uploads/2024/06/Otomatik-kendinden-kar-t-rma-manyetik-kupa-paslanmaz-elik-s-cakl-k-fark-kahve-kar-t.jpg_-600x600-1.webp",
    cat: "kitchenware",
    productName: "Automatic Self Stirring Mug",
    price: "1999",
    color: "Black",
    badge: true,
    sold: "Sold: 987",
    des: "",
  },
  {
    _id: 2007,
    img: spfTwo,
    cat: "kitchenware",
    productName: "Tea Table",
    price: "1350.00",
    color: "Gray",
    badge: true,
    sold: "Sold: 73",
    des: "",
  },
  {
    _id: 2002,
    img: "https://shahalami.pk/cdn/shop/products/2e45f8913acee6b3e3fd6fbad3d96e32_960x.jpg?v=1662383374",
    cat: "kitchenware",
    productName: "pop corn maker",
    price: "4599.00",
    color: "Red",
    badge: true,
    sold: "Sold: 69",
    des: "",
  },
  {
    _id: 2003,
    img: "https://www.ratdin.pk/cdn/shop/products/vegetable-chopper-cutter-food-slicer-multifunctional-14-in-1-977584_large.jpg?v=1708922226",
    cat: "kitchenware",
    productName: "Vegetable Chopper and Slicer",
    price: "1750.00",
    color: "Mixed",
    badge: true,
    sold: "Sold: 476",
    des: "",
  },
  {
    _id: 2004,
    img: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSkx1N6yxEvNC_auqMxbLk_raGOBEhu-G7izg&s",
    cat: "kitchenware",
    productName: " Knife sharpner",
    price: "800.00",
    color: "Red",
    badge: true,
    sold: "Sold: 381",
    des: "",
  },
  {
    _id: 2005,
    img: "https://static-01.daraz.pk/p/9713821abd674d0538aa6f2e6cefba72.jpg_750x750.jpg_.webp",
    cat: "kitchenware",
    productName: "Electric Stove",
    price: "2450.00",
    color: "White",
    badge: true,
    sold: "Sold: 2298",
    des: "",
  },
  {
    _id: 2006,
    img: "https://static-01.daraz.pk/p/517454c36abdfb32abb67d5ecb7790cf.jpg_750x750.jpg_.webp",
    cat: "kitchenware",
    productName: "Kitchen Shower Splash Faucet",
    price: "350.00",
    color: "Mixed",
    badge: true,
    sold: "Sold: 420",
    des: "",
  },
  {
    _id: 2007,
    img: "https://mines.pk/wp-content/uploads/2023/11/portable-electric-grinder-2-min.jpg",
    cat: "kitchenware",
    productName: "grinder",
    price: "1850.00",
    color: "Mixed",
    badge: true,
    sold: "Sold: 1993",
    des: "",
  },

  {
    _id: 2008,
    img: "https://m.media-amazon.com/images/I/61WDt315gaL._AC_UF1000,1000_QL80_.jpg",
    cat: "kitchenware",
    productName: "portable juicer ",
    price: "1799",
    color: "mix",
    badge: true,
    sold: "Sold: 932",
    des: "",
  },

  
  // =================== stationary ID:3000   ============
  {
    _id: 3000,
    img: "https://static-01.daraz.pk/p/8073746d51b2d09f86aabdac5df37e1f.jpg",
    cat: "stationary",
    productName: "LCD Writing & Drawing Tab",
    price: "749.00",
    color: "Mixed",
    badge: true,
    sold: "Sold: 496",
    des: "",
  },
  {
    _id: 3001,
    img: "https://hhcdropshipping.com/Member/wp-content/uploads/2024/05/WhatsApp-Image-2024-05-15-at-3.29.51-PM-900x862.jpeg",
    cat: "stationary",
    productName: "Frog Balancing Weight Machine",
    price: "2349.00",
    color: "Mixed",
    badge: true,
    sold: "Sold: 669",
    des: "",
  },
    {
    _id: 3002,
    img: "https://hhcdropshipping.com/Member/wp-content/uploads/2024/05/WhatsApp-Image-2024-05-15-at-2.10.59-PM-900x900.jpeg",
    cat: "stationary",
    productName: "Tetris Balance Stacking Tower",
    price: "1149.00",
    color: "Mixed",
    badge: true,
    sold: "Sold: 121",
    des: "",
  },
  {
    _id: 3002,
    img: "https://hhcdropshipping.com/Member/wp-content/uploads/2024/03/61mZPM9CUrL._SL1024_-900x900.jpg",
    cat: "stationary",
    productName: "Tic-Tac-Toe Game",
    price: "640.00",
    color: "wooden",
    badge: true,
    sold: "Sold: 201",
    des: "",
  },
  {
    _id: 3003,
    img: "https://hhcdropshipping.com/Member/wp-content/uploads/2023/12/WhatsApp-Image-2023-12-27-at-4.18.37-PM.jpeg",
    cat: "stationary",
    productName: "Magic Water Drawing Book ",
    price: "649.00",
    color: "Blue",
    badge: true,
    sold: "Sold: 416",
    des: "",
  },
  {
    _id: 3004,
    img: "https://hhcdropshipping.com/Member/wp-content/uploads/2023/11/WhatsApp-Image-2023-11-28-at-3.46.24-PM-900x906.jpeg",
    cat: "stationary",
    productName: "Baby Projector Torch 3cards ",
    price: "599.00",
    color: "Mix",
    badge: true,
    sold: "Sold: 456",
    des: "",
  },
  {
    _id: 3005,
    img: "https://hhcdropshipping.com/Member/wp-content/uploads/2023/11/6caf5b19-ca93-43b7-934f-ced022687830-900x898.jpeg",
    cat: "stationary",
    productName: "Moyu Magic Speed Cube",
    price: "1999.00",
    color: "",
    badge: true,
    sold: "Sold: 381",
    des: "",
  },
  {
    _id: 3006,
    img: "https://hhcdropshipping.com/Member/wp-content/uploads/2024/05/WhatsApp-Image-2024-05-20-at-6.35.26-PM.jpeg",
    cat: "stationary",
    productName: "Wooden World Map With 30 (Thirty) Flags",
    price: "2999.00",
    color: "mix",
    badge: true,
    sold: "Sold: 809",
    des: "",
  },
  {
    _id: 3007,
    img: "https://hhcdropshipping.com/Member/wp-content/uploads/2024/02/3129db65-2062-4f0b-b4da-2c50a4a2f70e.jpeg",
    cat: "stationary",
    productName: "Diy Assembly Physics Electric Circuit Toy (for childs)",
    price: "899.00",
    color: "mix",
    badge: true,
    sold: "Sold: 479",
    des: "",
  },




  
  // =================== lifestyle ID:4000   ============
  {
    _id: 4026,
    img: "https://hhcdropshipping.com/Member/wp-content/uploads/2024/06/WhatsApp-Image-2024-06-05-at-3.50.57-PM.jpeg",
    cat: "lifestyle",
    productName: "Stitched 3pc Full Print Kataan Silk Maxi ",
    price: "3599",
    color: "Blue",
    badge: true,
    sold: "Sold: 209",
    des: "",
  },
  {
    _id: 4011,
    img: "https://hhcdropshipping.com/Member/wp-content/uploads/2022/01/24-K-GOLDEN-900x900.jpg",
    cat: "lifestyle",
    productName: "Original 24k Gold Serum",
    price: "999.00",
    color: "Gold",
    badge: true,
    sold: "Sold: 695",
    des: "",
  },
  {
    _id: 4024,
    img: "https://hhcdropshipping.com/Member/wp-content/uploads/2024/07/WhatsApp-Image-2024-07-26-at-11.17.40-PM-2.jpeg",
    cat: "lifestyle",
    productName: "Black 2pc Suit Plain Shirt & Trouser",
    price: "1999.00",
    color: "Black",
    badge: true,
    sold: "Sold: 722",
    des: "",
  },
 
  {
    _id: 4000,
    img: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQU0mhyApAS98bmi57dyEEFvFhmbyfgRWgsSEDMa0L6le0tohaGwQPCVlU&s=10",
    cat: "lifestyle",
    productName: "BNB Whitening Rice kit ",
    price: "1599",
    color: "mix",
    badge: true,
    sold: "Sold: 583",
    des: "",
  },
  {
    _id: 4023,
    img: "https://hhcdropshipping.com/Member/wp-content/uploads/2024/08/WhatsApp-Image-2024-08-20-at-4.59.21-PM-1_cleanup.png",
    cat: "lifestyle",
    productName: "maria B Kids Embroidery 3pcs Lawn  Unstitched ",
    price: "2549.00",
    color: "Multiple",
    badge: true,
    sold: "Sold: 279",
    des: "",
  },
  
  {
    _id: 4021,
    img: "https://hhcdropshipping.com/Member/wp-content/uploads/2024/08/WhatsApp-Image-2024-08-23-at-10.26.43-AM.jpeg",
    cat: "lifestyle",
    productName: "Womens Simple Daily Long sleeve Silk Pajama Suit Set With Long Pants",
    price: "1999.00",
    color: "Purple, Black",
    badge: true,
    sold: "Sold: 409",
    des: "",
  },
  {
    _id: 4022,
    img: "https://hhcdropshipping.com/Member/wp-content/uploads/2024/08/WhatsApp-Image-2024-08-20-at-4.59.22-PM-1_cleanup.png",
    cat: "lifestyle",
    productName: "maria B Kids Embroidery 3pcs Lawn Unstitched ",
    price: "2549.00",
    color: "Multiple",
    badge: true,
    sold: "Sold: 231",
    des: "",
  },
  {
    _id: 4000,
    img: "https://hhcdropshipping.com/Member/wp-content/uploads/2024/06/WhatsApp-Image-2024-06-14-at-7.07.15-PM-2.jpeg",
    cat: "lifestyle",
    productName: "Hot Slimming Belt ",
    price: "1199",
    color: "Black",
    badge: true,
    sold: "Sold: 2041",
    des: "",
  },
  {
    _id: 4025,
    img: "https://hhcdropshipping.com/Member/wp-content/uploads/2024/07/WhatsApp-Image-2024-07-26-at-12.32.44-PM-scaled.jpeg",
    cat: "lifestyle",
    productName: "Fitross Watch For Men ",
    price: "1669",
    color: "Silver",
    badge: true,
    sold: "Sold: 41",
    des: "",
  },
  
    {
    _id: 4001,
    img: spfOne,
    cat: "lifestyle",
    productName: "Cap for Boys",
    price: "149.00",
    color: "Blank and White",
    badge: true,
    sold: "Sold: 932",
    des: "",
  },
  {
    _id: 4001,
    img: "https://hhcdropshipping.com/Member/wp-content/uploads/2024/05/H5f17bdd95e8d4dfe949f397de1aa7cecy-1.jpg",
    cat: "lifestyle",
    productName: "T9 Hair Trimmer",
    price: "1149.00",
    color: "Blank and Yellow",
    badge: true,
    sold: "Sold: 1029",
    des: "",
  },
  {
    _id: 4002,
    img: spfFour,
    cat: "lifestyle",
    productName: "Sun glasses",
    price: "220.00",
    color: "Black",
    badge: true,
    sold: "Sold: 986",
    des: "",
  },
  {
    _id: 4014,
    img: "https://hhcdropshipping.com/Member/wp-content/uploads/2024/05/mas-rdn-mandelic-acid-10pct-ha-lifestyle-900x900.webp",
    cat: "lifestyle",
    productName: "The Ordinary. Mandelic Acid 10% ",
    price: "1499.00",
    color: "",
    badge: true,
    sold: "Sold: 204",
    des: "",
  },
  {
    _id: 4014,
    img: "https://hhcdropshipping.com/Member/wp-content/uploads/2024/05/mas-rdn-mandelic-acid-10pct-ha-lifestyle-900x900.webp",
    cat: "lifestyle",
    productName: "The Ordinary. Mandelic Acid 10% ",
    price: "1499.00",
    color: "",
    badge: true,
    sold: "Sold: 187",
    des: "",
  },
  {
    _id: 4015,
    img: "https://hhcdropshipping.com/Member/wp-content/uploads/2024/05/mas-rdn-caffeine-solution-5pct-egcg-lifestyle-1-900x900.webp",
    cat: "lifestyle",
    productName: "The Ordinary: Caffeine Solution 5% + Egcg",
    price: "1499.00",
    color: "",
    badge: true,
    sold: "Sold: 116",
    des: "",
  },
  {
    _id: 4003,
    img: newArrThree,
    cat: "lifestyle",
    productName: "cloth Basket",
    price: "680.00",
    color: "Mixed",
    badge: true,
    sold: "Sold: 201",
    des: "",
  },
  {
    _id: 4004,
    img: "https://hhcdropshipping.com/Member/wp-content/uploads/2024/05/2-34.webp",
    cat: "lifestyle",
    productName: "Oniom Hair Seerum",
    price: "549.00",
    color: "Mixed",
    badge: true,
    sold: "Sold: 243",
    des: "",
  },
  {
    _id: 4004,
    img: newArrFour,
    cat: "lifestyle",
    productName: "Funny toys for babies",
    price: "590.00",
    color: "Mixed",
    badge: true,
    sold: "Sold: 51",
    des: "",
  },
  {
    _id: 4005,
    img: "https://m.media-amazon.com/images/I/61kKROUbHYL._AC_.jpg",
    cat: "lifestyle",
    productName: "crystal 16 colors",
    price: "2459.00",
    color: "Mixed",
    badge: true,
    sold: "Sold: 391",
    des: "",
  },
  {
    _id: 4006,
    img: "https://flyingcart.pk/cdn/shop/files/7749e07e1558ccbeb137a41e3cc74fe2_jpg_750x750_jpg.jpg?v=1711607377&width=1946",
    cat: "lifestyle",
    productName: "foldable storage bags",
    price: "799.00",
    color: "Mixed",
    badge: true,
    sold: "Sold: 1581",
    des: ""
  },

  {
    _id: 4007,
    img: "https://babyboutique.pk/cdn/shop/products/71p2MoyvMLS._AC_SL1500.jpg?v=1656098972",
    cat: "lifestyle",
    productName: "Dancing Cactus Toy with Recording",
    price: "1999.00",
    color: "Mixed",
    badge: true,
    sold: "Sold: 5134",
    des: "",
  },
  {
    _id: 4008,
    img: "https://static-01.daraz.pk/p/9f85c401ff3968dd5be1f60fc51ece7c.jpg",
    cat: "lifestyle",
    productName: "hair dryer cap towel",
    price: "359.00",
    color: "Mixed",
    badge: true,
    sold: "Sold: 489",
    des: "",
  },

  {
    _id: 4009,
    img: "https://m.media-amazon.com/images/I/51h0eJiJjAL._AC_UF1000,1000_QL80_.jpg",
    cat: "lifestyle",
    productName: "Ear Wax 6 pack",
    price: "599.00",
    color: "Mixed",
    badge: true,
    sold: "Sold: 91",
    des: "",
  },
  
  
  {
    _id: 4010,
    img: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS4ZTELlcviWeGub5Ntz5vV37p6GmicRDmUVA&s",
    cat: "lifestyle",
    productName: "Ecrin Hair Remover",
    price: "1249.00",
    color: "Mixed",
    badge: true,
    sold: "Sold: 391",
    des: "",
  },
  {
    _id: 4012,
    img: "https://hhcdropshipping.com/Member/wp-content/uploads/2024/04/WhatsApp-Image-2024-04-01-at-7.16.04-PM-1.jpeg",
    cat: "lifestyle",
    productName: "Golden Whitening Face Capsules (100)",
    price: "1099.00",
    color: "Gold",
    badge: true,
    sold: "Sold: 91",
    des: "",
  },
  {
    _id: 4013,
    img: "https://hhcdropshipping.com/Member/wp-content/uploads/2024/03/e174ed38364e51f848c3132c17b593f8.jpg",
    cat: "lifestyle",
    productName: "Rorec White Rice Serum",
    price: "849.00",
    color: "Water",
    badge: true,
    sold: "Sold: 173",
    des: "",
  },
  
  
  // =================== bags ID:5000  ============
  {
    _id: 5001,
    img: bestSellerTwo,
    cat: "bags",
    productName: "New Backpack",
    price: "1349.00",
    color: "Gray",
    badge: true,
    sold: "Sold: 431",
    des: "",
  },
  {
    _id: 5002,
    img: bestSellerFour,
    cat: "bags",
    productName: "Travel Bag",
    price: "1100.00",
    color: "Black",
    badge: true,
    sold: "Sold: 998",
    des: "",
  },



  
  // =================== homeappliances ID:6000  ============
  {
    _id: 6001,
    img: bestSellerOne,
    cat: "homeappliances",
    productName: "Flower Base",
    price: "340.00",
    color: "Blank and White",
    badge: true,
    sold: "Sold: 609",
    des: "",
  },
  {
    _id: 6002,
    img: bestSellerThree,
    cat: "homeappliances",
    productName: "Household materials",
    price: "499.00",
    color: "Mixed",
    badge: true,
    sold: "Sold: 117",
    des: "",
  },
  {
    _id: 6003,
    img: newArrOne,
    cat: "homeappliances",
    productName: "Round Table Clock",
    price: "849.00",
    color: "Black",
    badge: true,
    sold: "Sold: 149",
    des: "",
  },
  {
    _id: 6004,
    img: "https://static-01.daraz.pk/p/9313b9f3c48030001b0065c252ba0920.jpg",
    cat: "homeappliances",
    productName: "Automatic Toothpaste Dispenser & Holder",
    price: "759.00",
    color: "Blank and White",
    badge: true,
    sold: "Sold: 540",
    des: "",
  },
  {
    _id: 6005,
    img: "https://m.media-amazon.com/images/I/41bqns-AZ0L._AC_.jpg",
    cat: "homeappliances",
    productName: "Drain Clog Remover",
    price: "699.00",
    color: "Blank and White",
    badge: true,
    sold: "Sold: 1381",
    des: "",
  },
  {
    _id: 6006,
    img: "https://static-01.daraz.pk/p/4497e7981d2fd598e60b6dad3546f566.jpg",
    cat: "homeappliances",
    productName: "Nano Magic tape",
    price: "328.00",
    color: "Blank and White",
    badge: true,
    sold: "Sold: 1505",
    des: "",
  },
  {
    _id: 6007,
    img: "https://static-01.daraz.pk/p/2fac500859eeb9b21dfab71cad0ffec5.jpg_750x750.jpg_.webp",
    cat: "homeappliances",
    productName: "water spray gun",
    price: "699.00",
    color: "Blank and White",
    badge: true,
    sold: "Sold: 1396",
    des: "",
  },

  
  // =================== groceries ID:7000   ============
  {
    _id: 7001,
    img: "https://static-01.daraz.pk/p/9313b9f3c48030001b0065c252ba0920.jpg",
    cat: "groceries",
    productName: "Automatic Toothpaste Dispenser & Holder",
    price: "759.00",
    color: "White",
    badge: true,
    sold: "Sold: 461",
    des: "",
  },
    {
    _id: 7003,
    img: "https://hhcdropshipping.com/Member/wp-content/uploads/2024/06/WhatsApp-Image-2024-06-07-at-11.08.56-PM-900x873.jpeg",
    cat: "groceries",
    productName: "Cat Shaped Double Food Bowl Pet Plastic",
    price: "549.00",
    color: "Blue",
    badge: true,
    sold: "Sold: 67",
    des: "",
  },
  {
    _id: 7000,
    img: "https://hhcdropshipping.com/Member/wp-content/uploads/2024/05/WhatsApp-Image-2024-05-25-at-11.52.11-AM-1.jpeg",
    cat: "groceries",
    productName: "Pack Of 4 New Mould Style Fried Egg Pan",
    price: "693.00",
    color: "Mixed",
    badge: true,
    sold: "Sold: 123",
    des: "",
  },
  {
    _id: 7004,
    img: "https://hhcdropshipping.com/Member/wp-content/uploads/2024/01/WhatsApp-Image-2023-10-20-at-4.25.54-PM_cleanup.png",
    cat: "groceries",
    productName: "Nano Tooth Brush",
    price: "449.00",
    color: "Mixed",
    badge: true,
    sold: "Sold: 407",
    des: "",
  },
  {
    _id: 7005,
    img: "https://hhcdropshipping.com/Member/wp-content/uploads/2024/03/1-59-1.jpg",
    cat: "groceries",
    productName: "Baby Finger Toothbrush Silicon Toothbrush",
    price: "399.00",
    color: "Mixed",
    badge: true,
    sold: "Sold: 1246",
    des: "",
  },
   
];

// =================== PaginationItems End here =================
